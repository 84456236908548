footer{
    .footer-nav-cont{
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;

        .footer-link{
            font-weight: 500;
            display: block;
            text-align: right;
            padding: 5px 5px;
            flex-basis: 50%;
            align-self: flex-start;
        }
    }

    @include media-breakpoint-down(lg){
        .footer-nav-cont{
            align-items: flex-start;

            .footer-link{
                text-align: left;
            }
        }
    }
}