.section-child-hero{
    position: relative;
    overflow: hidden;

    .hero-img-cont{
        display: flex;
        justify-content: flex-end;
    }

    @include media-breakpoint-down(lg){
        .hero-img-cont{
            justify-content: flex-end;
            position: absolute;
            bottom: -50px;
            right: -100px;
            opacity: .5;
            width: 350px;

            img{
                width: 100%;
            }
        }
    }
    @include media-breakpoint-down(md){
        .hero-img-cont{
            right: -50px;
            width: 250px;
        }
    }
}