@import '../../scss/_partials/article-list';

.section-hero{
    .col-hero-text{
        display: flex;
        align-items: center;
    }
    .hero-img-cont{
        width: 43vw;
        overflow: hidden;
        position: relative;
        height: 550px;

        img{
            height: 100%;
            display: block;
            width: auto;
            max-height: 100%;
        }
    }
}

.section-cards{
    margin-top: -50px;
    position: relative;
    z-index: 10;

    .icon-card{
        // border: 3px solid $black;
        // border-radius: 10px;
        // display: flex;
        // flex-direction: column;
        // overflow: hidden;

        .img-cont{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 50px;
            padding-bottom: 50px;
            border-bottom: 3px solid $black;
            background: $tan;

            img{
                width: 30%;
                max-height: 120px;
                @include media-breakpoint-down(xxl){
                    max-height: 103px;
                }
                @include media-breakpoint-down(xl){
                    max-height: 85px;
                }
            }
        }

        .copy-cont{
            background: $white;
            padding: 30px 20px 20px 20px;

            p{
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-down(lg){
        margin-top: -30px;
        
        .icon-card{
            flex-direction: row;

            .img-cont{
                border-bottom: none;
                border-right: 3px solid $black;
                width: 30%;

                img{
                    max-height: 50px;
                }
            }
        }
    }

    @include media-breakpoint-down(md){
        .icon-card{
            .img-cont{
                flex-basis: 20%;
                padding-top: 10px;
                padding-bottom: 10px;

                img{
                    width: 60%;
                }
            }
            .copy-cont{
                flex-basis: 80%;
                padding: 20px;
            }
        }
    }
}

.section-match-faster{
    .number-cont{
        background: $white;
        border: 3px solid $gray;
        border-radius: 10px;
        display: flex;
        width: 20%;
        justify-content: center;
    }
    .copy-cont{

    }
}