
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 900;
    src: url("/static/fonts/Rubik-Black.woff2") format("woff2"), url("/static/fonts/Rubik-Black.woff") format("woff"), url("/static/fonts/Rubik-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 800;
    src: url("/static/fonts/Rubik-ExtraBold.woff2") format("woff2"), url("/static/fonts/Rubik-ExtraBold.woff") format("woff"), url("/static/fonts/Rubik-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    src: url("/static/fonts/Rubik-Bold.woff2") format("woff2"), url("/static/fonts/Rubik-Bold.woff") format("woff"), url("/static/fonts/Rubik-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 300;
    src: url("/static/fonts/Rubik-Light.woff2") format("woff2"), url("/static/fonts/Rubik-Light.woff") format("woff"), url("/static/fonts/Rubik-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    src: url("/static/fonts/Rubik-Medium.woff2") format("woff2"), url("/static/fonts/Rubik-Medium.woff") format("woff"), url("/static/fonts/Rubik-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    src: url("/static/fonts/Rubik-Regular.woff2") format("woff2"), url("/static/fonts/Rubik-Regular.woff") format("woff"), url("/static/fonts/Rubik-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    src: url("/static/fonts/Rubik-SemiBold.woff2") format("woff2"), url("/static/fonts/Rubik-SemiBold.woff") format("woff"), url("/static/fonts/Rubik-SemiBold.ttf") format("truetype");
}