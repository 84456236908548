// .section-articles{
//     .col{
//         .article-card{
//             .img-cont{
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;

//                 img{
//                     width: 100%;
//                     height: auto;
//                 }
//             }
//         }
//     }
// }

.section-articles{
    .article-card{
        .img-cont{
            max-height: 200px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 100%;
                height: auto;
            }
        }
        .copy-cont{
            background: $white;
            padding: 30px 20px 20px 20px;

            .article-title{
                margin-bottom: 5px
            }
        }
    }
}