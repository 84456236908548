@import './fonts';

$font-family: 'Rubik', Arial, Helvetica, Sans-serif;
$font-family-base: 'Rubik', Arial, Helvetica, Sans-serif;

$tan: #F9F9F2;
$d-tan: #F5F5E9;
$xd-tan: #ededdd;
$gray: #919191;
$d-gray: #7c7c7c;
$black: #1C1C1C;
$l-black: #4c4c4c;
$white: #ffffff;

$green: #2DC86B;
$d-green: #037e34;
$l-green: #8BEAB1;
$xl-green: #DDFFE4;
$xxl-green: #E8FAED;

$transparent: rgba( 0, 0, 0, 0 );

$border-primary: 3px solid $black;

$spacer: 30px;
$spacers: (
  0: 0,
  1: $spacer * .5,
  2: $spacer,
  3: $spacer * 1.5,
  4: $spacer * 2,
  5: $spacer * 3,
);

$body-bg: $tan;

$theme-colors: (
  "primary":    $black,
  "secondary":  $green,
  "success":    $green,
  "lgreen":    $l-green,
  "xlgreen":    $xl-green,
  "xxlgreen":    $xxl-green,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,
//   "light":      $light,
//   "dark":       $dark
);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700; 
$headings-font-weight: 700;
$nav-link-font-weight: 700;
$link-font-weight: 500;
