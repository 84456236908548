// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "./variables";

// 3. Include remainder of required Bootstrap stylesheets
@import "../../../node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/helpers";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";

@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/utilities/api";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
// @import "../../../node_modules/bootstrap/scss/utilities/api";

// 8. include supporting custom files
@import "./footer";
@import "./_partials/nav";

// 9. Add additional custom code here
// @font-face {
//     font-family: 'Rubik';
//     src: url('../../fonts/') format('truetype');
// }


body{
    font-family: $font-family;
    font-size: 16px;
    color: $black;
    background-color: $tan;

    &.scholarship-filter-active{
        &::after{
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            opacity: .5;
            z-index: 60;
        }
    }
}

h1{
    font-family: $font-family;
    font-weight: 700;
    font-size: 3rem;
}

h2{
    font-family: $font-family;
    font-weight: 700;
    font-size: 2.1rem;
}

h3{
    font-family: $font-family;
    font-weight: 700;
    font-size: 1.5rem;
}

h4{
    font-family: $font-family;
    font-weight: 600;
    font-size: 1.25rem;
}
h5{
    font-family: $font-family;
    font-weight: 600;
    font-size: 1rem;
}

p{
    font-family: $font-family;
    font-size: 1rem;
    line-height: 1.6rem;
    color: $l-black;
}

.p-sub{
    font-size: .8rem;
    line-height: 1rem;
    color: $black;
    font-weight: 300;
    text-transform: uppercase;
}

.p-sup{
    font-size: .8rem;
    line-height: 1rem;
    vertical-align: super;
}

a{
    font-family: $font-family;
    color: $black;
    text-decoration: none;

    &:hover{
        text-decoration: none;
        color: $black;
    }

    &.inline{
        color: $green;
        text-decoration: underline;

        &:hover{
            color: $l-green;
            text-decoration: none;
        }
    }
}

.bold{
    font-weight: 700;
}

.date{
    font-weight: 300;
    color: $black;
    text-transform: uppercase;
}

.btn{
    text-align: center;
    padding: 10px 30px;
    border: 2px solid $black;
    box-shadow: 5px 5px 0 0 $black;
    border-radius: 5px;
    text-decoration: none;
    color: $black;
    transition: .1s all linear;
    font-weight: 500;
    font-size: 1.125rem;
    display: inline-block;

    &:hover{
        text-decoration: none;
        opacity: 1;
        // box-shadow: 0 0 0 $transparent;
        box-shadow: none;
        color: $black;
    }

    // btn priamry
    &.btn-primary{
        background: $white;
    }

    // btn secondary
    &.btn-secondary{
        background: $l-green;
    }

    // btn small
    &.btn-sm{
        padding: 5px 15px;
        font-size: .8rem;
    }

    &.disabled{
        opacity: .5;
        cursor: default;
        box-shadow: none;
    }
}

section{
    overflow: hidden;
}

input, select{
    &:active, &:focus{
        outline: none !important;
        border: 2px solid $green !important;
    }
}

.text-dgray{
    color: $gray;
}

/*------------------- UTILITIES --------------------*/
.bb-3{
    border-bottom: 3px solid $black;
}
.bl-3{
    border-left: 3px solid $black;
}
.by-3{
    border-top: $border-primary;
    border-bottom: $border-primary;
}
.bt-3{
    border-top: $border-primary;
}
.ba-3{
    border: 3px solid $black;
}
.bb-0{
    border-bottom: 0;
}
.bb-lg-3{
    @include media-breakpoint-up(lg){
        border-bottom: 3px solid $black;
    }
}
.text-left{
    text-align: left;
}






/*---------------------- CARDS -----------------------*/
.card{
    border: 3px solid $black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all .1s linear;

    &:hover{
        cursor: pointer;
        box-shadow: 5px 5px 0 0 $black;
    }
}




/*---------------------- RESPONSIVE STYELS -----------------------*/
@include media-breakpoint-down(lg){
    
}
@include media-breakpoint-down(md){
    h1{
        font-family: $font-family;
        font-weight: 700;
        font-size: 2.25rem;
    }
    
    h2{
        font-family: $font-family;
        font-weight: 700;
        font-size: 1.85rem;
    }
    
    h3{
        font-family: $font-family;
        font-weight: 700;
        font-size: 1.4rem;
    }
    
    h4{
        font-family: $font-family;
        font-weight: 600;
        font-size: 1.1rem;
    }
    
    p{
        font-family: $font-family;
        font-size: 1rem;
        line-height: 1.6rem;
        color: $l-black;
    }

}
@include media-breakpoint-down(sm){
    h1{
        font-family: $font-family;
        font-weight: 700;
        font-size: 2rem;
    }
    
    h2{
        font-family: $font-family;
        font-weight: 700;
        font-size: 1.7rem;
    }
    
    h3{
        font-family: $font-family;
        font-weight: 700;
        font-size: 1.3rem;
    }
    
    h4{
        font-family: $font-family;
        font-weight: 600;
        font-size: 1rem;
    }
    
    p{
        font-family: $font-family;
        font-size: 1rem;
        line-height: 1.6rem;
        color: $l-black;
    }

}