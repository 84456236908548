
body{
    &::after{
        transition: all .2s;
        opacity: 0;
    }

    .navbar{
        z-index: 60;
        background: $tan;

        .container{
            .navbar-brand{
                @include media-breakpoint-down(lg){
                    img{
                        width: 250px;
                    }
                }
            }
            .navbar-collapse{
                .nav-item{
                    display: flex;
                    align-items: flex-end;
                }
                .nav-link{
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 0;
                }
                &.faq-active{
                    .nav-item-faq{
                        .nav-link{
                            color: $black;
                            font-weight: 700;
                        }
                    }
                }
                &.scholarships-active{
                    .nav-item-scholarships{
                        .nav-link{
                            color: $black;
                            font-weight: 700;
                        }
                    }
                }
                &.nms-scholarships-active{
                    .nav-item-nms-scholarships{
                        .nav-link{
                            color: $black;
                            font-weight: 700;
                        }
                    }
                }
                &.nms-scores-active{
                    .nav-item-nms-scores{
                        .nav-link{
                            color: $black;
                            font-weight: 700;
                        }
                    }
                }
                &.about-active{
                    .nav-item-about{
                        .nav-link{
                            color: $black;
                            font-weight: 700;
                        }
                    }
                }
                &.articles-active{
                    .nav-item-articles{
                        .nav-link{
                            color: $black;
                            font-weight: 700;
                        }
                    }
                }

                // mobile nav styles
                @include media-breakpoint-down(lg){
                    transition: height .2s;
                    height: 0;
                    visibility: hidden;

                    .navbar-nav{
                        padding-top: 30px;
                        padding-bottom: 30px;

                        .nav-link{
                            text-align: left;
                            padding-top: 20px;
                        }
                    }
                }
            }
        
            // mobile nav button
            .mobile-nav-button{
                display: block;
                @include media-breakpoint-up(lg){
                    display: none;
                }
        
                padding: 10px 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: transparent;
                border: 2px solid $black;
                border: none;
                border-radius: 5px;
        
                
                &.active {
                    .menu-item {
                        &:first-child {
                            // transition-delay: .2s;
                            rotate: 45deg;
                            transform: translateX(5px);
                        }
                        &:nth-child(2) {
                            opacity: 0;
                        }
                        &:nth-child(3) {
                            transition-delay: .1s;
                            rotate: -45deg;
                            transform: translate(11px, -6px);
                        }
                    }
                }
        
                .menu-item {          
                    display: block;
                    width: 30px;
                    height: 3px;
                    border-radius: 5px;
                    background: $black;
                    margin-bottom: 5px;
                    transition: all .2s;
        
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                }
            }
        }
        
    }


    // mobile nav active
    &.mobile-nav-active{
        overflow: hidden;
        
        &::after{
            transition: all .2s;
            opacity: .3;
            content: "";
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            background: $black;
            z-index: 50;
        }

        .navbar{
            .navbar-collapse{
                transition: height .2s;
                height: 350px;
                visibility: visible;
            }
        }
    }
}

