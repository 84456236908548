.section-about{
    .headshot-img-cont{
        width: auto;
        border: 3px solid $black;
        border-radius: 10px;
        background: $white;
        display: inline-block;
        
        img{
            margin-bottom: -1px;
        }
    }
}