.section-faq{
    .faq-item{
        border-radius: 10px;

        .faq-question{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &:hover{
                cursor: pointer;
            }

            &:after{
                content: "";
                height: 20px;
                width: 20px;
                display: block;
                background-image: url('../images/icons/icon-plus-black.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }

        .faq-answer{
            p{
                padding-top: 0;
                transition: all .2s ease;
                overflow: hidden;
                height: 0;
            }
            
        }

        &.active{
            .faq-question{
                &:after{
                    background-image: url('../images/icons/icon-minus-black.svg');
                }
            }
            .faq-answer{
                p{
                    padding-top: 15px;
                    transition: all .2s ease;
                    height: auto;
                }
                
            }
            
        }
    }
}